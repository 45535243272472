.footer{
    background-color: #2396CD;
    height:6%;
    width: 100%;
    max-width: 1800px;
    position:fixed;
    bottom:0;
    display: flex;
    display: -ms-flexbox;
    align-items: center;
    justify-content: space-between;
    padding: 5px;
    z-index: 3000;
}

.footer div{
    display: flex;
    display: -ms-flexbox;

}

#socials svg{
    height:100%;
    margin-right: 5px;
    cursor: pointer;
    opacity:0.5;
}

#socials svg:hover{
    opacity:1;
}

.social{
    margin-right:5px;
}


.social-icon circle{
    fill:#FFFFFF;
}

.social-icon path{
    fill:#2396CD;
}
a{
    height:100%;
}

#logo{
    height:100%;
    fill:white;
}

#logo :hover{
    cursor: pointer;
}
@media only screen and (min-width: 1020px) {
    .footer{top:0; right: 0; bottom:initial; height: auto; width: 10%;flex-direction: column; background-color: white; border-bottom-left-radius: 10px; margin-top: 15px;}
    #logo{height:3vh;fill:#16284A; opacity:0.5;}
    .social-icon path{fill:#16284A;}
    #logo:hover{opacity:1}
    a{height:30px;
    }
}