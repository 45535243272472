.menu{
    position: fixed;
    width: 30px;
    top:20px;
    right:20px;
    z-index: 3000;
    opacity: 0.5;
}

#visible{
    display: block;
}

#invisible{
    display: none;
}