.start{
    width: 100%;
    height: 100%;
    display: flex;
    display: -ms-flexbox;
    flex-direction: column;
    background-color: white;
    justify-content: space-around;
    align-items: center;
    position: absolute;
    padding:20px;
    z-index: 8000;
    background-image: url("https://interactief.vrtnws.be/gravenvanbekendebelgen/fotos/grafback_2.jpg");
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
}


#up{
    transform: translate3d(0, -150vh, 0);
    transition: transform 0.7s ease-in-out;

}

.start #contain{
    z-index: 900;
    height: 100%;
    width:100%;
    max-width: 1400px;
    display: flex;
    display: -ms-flexbox;
    flex-direction: column;
    justify-content: space-around;
    position: relative;
}

.start h1{
    text-transform: uppercase;
    color: white;
    font-family: bold;
    font-size: 30px;
    line-height:30px;
    width: 95%;
}

.start p{
    color:white;
    font-family: regular;
    max-width: 100%;
}

.start a{
    display: block;
    margin: 0 auto;
    position: relative;
    height:20px;
    fill: white;
    opacity: 1;

}

.start a svg{
    height: 100%;
}

.cta{
    background-color:white;
    padding:18px 15px 15px 15px;
    text-align: center;
    border-radius: 30px;
    color:#16284A;
    font-family: bold;
    text-transform: uppercase;
    font-size:20px;
    z-index: 80;
    width:60%;
    margin:0 auto;
    cursor: pointer;
}

.cta:hover{
    background-color: #2396CD;
    color:white;
}

#wait{
    color:#16284A;
    background-color: white;
    opacity:0.5;
    cursor: default;
}

video{
    position: fixed;
    display: none;
    right: 0;
    bottom: 0;
    min-width: 100%;
    min-height: 100%;
}

@media only screen and (min-width: 1020px) {
    .start #contain{justify-content: space-between;}
    .start{background-image: none; padding: 5%;}
    .start a{right:20px;position: absolute;top:-2%; height:30px;}
    .start h1{font-size:90px;line-height:70px;width:35vw; max-width:500px;}
    .start p{width: 35vw; font-size: 23px; line-height: 25px; max-width:500px;}
    .cta{width:30%;position:relative;margin:0 auto; bottom:0%;}
    video{display: block}
}

@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    video{display:none;}
    .start{background-image: url("https://interactief.vrtnws.be/gravenvanbekendebelgen/fotos/grafback_2.jpg");background-color: transparent;}
}