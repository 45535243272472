#close{
    padding: 0 !important;
    cursor:pointer;
    opacity: 0.5;
    margin-top: 20px;
}

#close:hover{
    opacity: 1;
}

#dialog{
    z-index: 9999;
}
