* {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  box-sizing: border-box;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}


.navigation img{
  width: 50px;
  border-radius: 100%;
}

.navigation {
  width: 100%;
  padding-bottom: 5vh;
  height:100%;
  position: fixed;
  top:0;
  left: 0;
  -webkit-transform: translate3d(-100vw, 0, 0);
          transform: translate3d(-100vw, 0, 0);
  -webkit-transition: -webkit-transform .3s cubic-bezier(0, .52, 0, 1);
  transition: -webkit-transform .3s cubic-bezier(0, .52, 0, 1);
  transition: transform .3s cubic-bezier(0, .52, 0, 1);
  transition: transform .3s cubic-bezier(0, .52, 0, 1), -webkit-transform .3s cubic-bezier(0, .52, 0, 1);
  overflow:auto;
  z-index: 1000;
  -webkit-overflow-scrolling: touch;

}

.item{
  padding: 20px 10px 20px 30px;
  background-color: #f1f2f4;
  display: flex;
  display: -ms-flexbox;
  align-items: center;
  border-bottom: 3px solid white;
}

.item:hover{
  background-color: #BBE0F0;
  cursor: pointer;
}


.item:last-child{
  border-bottom: transparent;
}

.item h2{
  padding-left: 10px;
  color: #16284A;
    display: block;
}

#hide{
  -webkit-transform: translate3d(-100vw, 0, 0);
          transform: translate3d(-100vw, 0, 0);
}

#show{
  -webkit-transform: translate3d(0vw, 0, 0);
          transform: translate3d(0vw, 0, 0);
}


@media only screen and (min-width: 1020px) {
  #hide{
    -webkit-transform: translate3d(0vw, 0, 0);
            transform: translate3d(0vw, 0, 0);
  }
}

/*
vrtnws
const config = {
    apiKey: "AIzaSyA8p0xYVlP8PIz0KK-2hab1MC9fKJuChiA",
    authDomain: "api-project-7429617504.firebaseapp.com",
    databaseURL: "https://api-project-7429617504.firebaseio.com",
    projectId: "api-project-7429617504",
    storageBucket: "api-project-7429617504.appspot.com",
    messagingSenderId: "7429617504"
};


robbe
const config = {
    apiKey: "AIzaSyDOSKl2zzTZtidmAWGdJZr0JQ5EdS8q1tU",
    authDomain: "testing-89824.firebaseapp.com",
    databaseURL: "https://testing-89824.firebaseio.com",
    projectId: "testing-89824",
    storageBucket: "testing-89824.appspot.com",
    messagingSenderId: "841545172903"
};

 */
.menu{
    position: fixed;
    width: 30px;
    top:20px;
    right:20px;
    z-index: 3000;
    opacity: 0.5;
}

#visible{
    display: block;
}

#invisible{
    display: none;
}
.footer{
    background-color: #2396CD;
    height:6%;
    width: 100%;
    max-width: 1800px;
    position:fixed;
    bottom:0;
    display: flex;
    display: -ms-flexbox;
    align-items: center;
    justify-content: space-between;
    padding: 5px;
    z-index: 3000;
}

.footer div{
    display: flex;
    display: -ms-flexbox;

}

#socials svg{
    height:100%;
    margin-right: 5px;
    cursor: pointer;
    opacity:0.5;
}

#socials svg:hover{
    opacity:1;
}

.social{
    margin-right:5px;
}


.social-icon circle{
    fill:#FFFFFF;
}

.social-icon path{
    fill:#2396CD;
}
a{
    height:100%;
}

#logo{
    height:100%;
    fill:white;
}

#logo :hover{
    cursor: pointer;
}
@media only screen and (min-width: 1020px) {
    .footer{top:0; right: 0; bottom:auto; bottom:initial; height: auto; width: 10%;flex-direction: column; background-color: white; border-bottom-left-radius: 10px; margin-top: 15px;}
    #logo{height:3vh;fill:#16284A; opacity:0.5;}
    .social-icon path{fill:#16284A;}
    #logo:hover{opacity:1}
    a{height:30px;
    }
}
.start{
    width: 100%;
    height: 100%;
    display: flex;
    display: -ms-flexbox;
    flex-direction: column;
    background-color: white;
    justify-content: space-around;
    align-items: center;
    position: absolute;
    padding:20px;
    z-index: 8000;
    background-image: url("https://interactief.vrtnws.be/gravenvanbekendebelgen/fotos/grafback_2.jpg");
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
}


#up{
    -webkit-transform: translate3d(0, -150vh, 0);
            transform: translate3d(0, -150vh, 0);
    -webkit-transition: -webkit-transform 0.7s ease-in-out;
    transition: -webkit-transform 0.7s ease-in-out;
    transition: transform 0.7s ease-in-out;
    transition: transform 0.7s ease-in-out, -webkit-transform 0.7s ease-in-out;

}

.start #contain{
    z-index: 900;
    height: 100%;
    width:100%;
    max-width: 1400px;
    display: flex;
    display: -ms-flexbox;
    flex-direction: column;
    justify-content: space-around;
    position: relative;
}

.start h1{
    text-transform: uppercase;
    color: white;
    font-family: bold;
    font-size: 30px;
    line-height:30px;
    width: 95%;
}

.start p{
    color:white;
    font-family: regular;
    max-width: 100%;
}

.start a{
    display: block;
    margin: 0 auto;
    position: relative;
    height:20px;
    fill: white;
    opacity: 1;

}

.start a svg{
    height: 100%;
}

.cta{
    background-color:white;
    padding:18px 15px 15px 15px;
    text-align: center;
    border-radius: 30px;
    color:#16284A;
    font-family: bold;
    text-transform: uppercase;
    font-size:20px;
    z-index: 80;
    width:60%;
    margin:0 auto;
    cursor: pointer;
}

.cta:hover{
    background-color: #2396CD;
    color:white;
}

#wait{
    color:#16284A;
    background-color: white;
    opacity:0.5;
    cursor: default;
}

video{
    position: fixed;
    display: none;
    right: 0;
    bottom: 0;
    min-width: 100%;
    min-height: 100%;
}

@media only screen and (min-width: 1020px) {
    .start #contain{justify-content: space-between;}
    .start{background-image: none; padding: 5%;}
    .start a{right:20px;position: absolute;top:-2%; height:30px;}
    .start h1{font-size:90px;line-height:70px;width:35vw; max-width:500px;}
    .start p{width: 35vw; font-size: 23px; line-height: 25px; max-width:500px;}
    .cta{width:30%;position:relative;margin:0 auto; bottom:0%;}
    video{display: block}
}

@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    video{display:none;}
    .start{background-image: url("https://interactief.vrtnws.be/gravenvanbekendebelgen/fotos/grafback_2.jpg");background-color: transparent;}
}

/* Safari 4.0 - 8.0 */
@-webkit-keyframes appear {
    0%   {opacity:0;}
    100% {opacity:1;}
}

/* Standard syntax */
@keyframes appear {
    0%   {opacity:0;}
    100% {opacity:1;}
}


.content{
    padding: 10px 0 5vh 0;
}


.bio{
    margin-bottom: 25px;
    padding:15px 15px 0 15px;
}

.bio p{
    color:#818b9d;
}


.bio img{
    width: 130px;
    border-radius: 100%;
}


.text{
    padding:0 15px 25px 15px;
}

.content img{
    max-width: 100%;
}

.app{
    -webkit-animation-name: appear; /* Safari 4.0 - 8.0 */
    -webkit-animation-duration: 2s; /* Safari 4.0 - 8.0 */
    animation-name: appear;
    animation-duration: 2s;
}

.navi{
    height:50px;
    color:white;
    display: flex;
    display: -ms-flexbox;
    justify-content: space-between;
}

.navi div{
    width: 49%;
    display: flex;
    display: -ms-flexbox;
    justify-content: center;
    align-items: center;
}

.navi svg{
    fill: #16284A;
    height: 100%;
}

@media only screen and (min-width: 1020px) {
    .container{display: flex; height:100%;    display: -ms-flexbox;
    }
    .tekst{order:1; display: flex;    display: -ms-flexbox;
        flex-direction: column;}
    .text{overflow-y: scroll;}
    .images{order:0; display: flex;    display: -ms-flexbox;
        flex-direction: column; height: 100%;}
    .images img{max-height:50%; max-width: none; max-width: initial;}
    .bio h2 {display: inline;}
    .openbutton{display:none;}
    .bio h2{font-size: 1.3em;}
    .bio p{font-weight: 600;}
}

#close{
    padding: 0 !important;
    cursor:pointer;
    opacity: 0.5;
    margin-top: 20px;
}

#close:hover{
    opacity: 1;
}

#dialog{
    z-index: 9999;
}

@font-face {
    font-family: 'bold';
    src: url(../../static/media/formadjrdisplay_bold-webfont.f9ff2210.woff2) format('woff2'),
    url(../../static/media/formadjrdisplay_bold-webfont.f9a5a48d.woff) format('woff');
    font-weight: normal;
    font-style: normal;

}
@font-face {
    font-family: 'light';
    src: url(../../static/media/formadjrdisplay_light-webfont.ca296781.woff2) format('woff2'),
    url(../../static/media/formadjrdisplay_light-webfont.819bbbc5.woff) format('woff');
    font-weight: normal;
    font-style: normal;

}
@font-face {
    font-family: 'disp_reg';
    src: url(../../static/media/formadjrdisplay-webfont.9585ba54.woff2) format('woff2'),
    url(../../static/media/formadjrdisplay-webfont.3b8612e8.woff) format('woff');
    font-weight: normal;
    font-style: normal;

}
@font-face {
    font-family: 'regular';
    src: url(../../static/media/formadjrmicro-regular-webfont.670f557e.woff2) format('woff2'),
    url(../../static/media/formadjrmicro-regular-webfont.ed2b4230.woff) format('woff');
    font-weight: normal;
    font-style: normal;

}

html{
    height:100%;
}
.content-container{
    max-width: 1800px;
    margin: 0 auto;
}

h2{
    font-family: bold;
    color:#16284A;
}

p{
    font-family: regular;
    color:#16284A;
}

.open_close{
    width:10%;
    position: fixed;
    top: 3%;
    right:5%;
    opacity:0.5;
}


@media only screen and (min-width: 1020px) {
    .navigation{width: 450px; position: relative; overflow-y: scroll; height: 100%; padding-bottom: 0;}
    .content-container{display: flex;    display: -ms-flexbox;
        height: 100%; overflow: hidden}
    .content{width:100%; height:100%;  padding:0;}
    .body{height:100vh;}
}



@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    .content{ overflow-y: scroll;}
    .navi{display:none;}
    .images{max-width: 50%;}
    .images img{max-height:50vh;}
    .tekst{width: 50%;}
    .cta{left:30%; position: absolute;}
    .start{background-image: url("https://interactief.vrtnws.be/gravenvanbekendebelgen/fotos/grafback_2.jpg");}
}
