
/* Safari 4.0 - 8.0 */
@-webkit-keyframes appear {
    0%   {opacity:0;}
    100% {opacity:1;}
}

/* Standard syntax */
@keyframes appear {
    0%   {opacity:0;}
    100% {opacity:1;}
}


.content{
    padding: 10px 0 5vh 0;
}


.bio{
    margin-bottom: 25px;
    padding:15px 15px 0 15px;
}

.bio p{
    color:#818b9d;
}


.bio img{
    width: 130px;
    border-radius: 100%;
}


.text{
    padding:0 15px 25px 15px;
}

.content img{
    max-width: 100%;
}

.app{
    -webkit-animation-name: appear; /* Safari 4.0 - 8.0 */
    -webkit-animation-duration: 2s; /* Safari 4.0 - 8.0 */
    animation-name: appear;
    animation-duration: 2s;
}

.navi{
    height:50px;
    color:white;
    display: flex;
    display: -ms-flexbox;
    justify-content: space-between;
}

.navi div{
    width: 49%;
    display: flex;
    display: -ms-flexbox;
    justify-content: center;
    align-items: center;
}

.navi svg{
    fill: #16284A;
    height: 100%;
}

@media only screen and (min-width: 1020px) {
    .container{display: flex; height:100%;    display: -ms-flexbox;
    }
    .tekst{order:1; display: flex;    display: -ms-flexbox;
        flex-direction: column;}
    .text{overflow-y: scroll;}
    .images{order:0; display: flex;    display: -ms-flexbox;
        flex-direction: column; height: 100%;}
    .images img{max-height:50%; max-width: initial;}
    .bio h2 {display: inline;}
    .openbutton{display:none;}
    .bio h2{font-size: 1.3em;}
    .bio p{font-weight: 600;}
}
