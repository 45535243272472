@font-face {
    font-family: 'bold';
    src: url('fonts/formadjrdisplay_bold-webfont.woff2') format('woff2'),
    url('fonts/formadjrdisplay_bold-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}
@font-face {
    font-family: 'light';
    src: url('fonts/formadjrdisplay_light-webfont.woff2') format('woff2'),
    url('fonts/formadjrdisplay_light-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}
@font-face {
    font-family: 'disp_reg';
    src: url('fonts/formadjrdisplay-webfont.woff2') format('woff2'),
    url('fonts/formadjrdisplay-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}
@font-face {
    font-family: 'regular';
    src: url('fonts/formadjrmicro-regular-webfont.woff2') format('woff2'),
    url('fonts/formadjrmicro-regular-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}

html{
    height:100%;
}
.content-container{
    max-width: 1800px;
    margin: 0 auto;
}

h2{
    font-family: bold;
    color:#16284A;
}

p{
    font-family: regular;
    color:#16284A;
}

.open_close{
    width:10%;
    position: fixed;
    top: 3%;
    right:5%;
    opacity:0.5;
}


@media only screen and (min-width: 1020px) {
    .navigation{width: 450px; position: relative; overflow-y: scroll; height: 100%; padding-bottom: 0;}
    .content-container{display: flex;    display: -ms-flexbox;
        height: 100%; overflow: hidden}
    .content{width:100%; height:100%;  padding:0;}
    .body{height:100vh;}
}



@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    .content{ overflow-y: scroll;}
    .navi{display:none;}
    .images{max-width: 50%;}
    .images img{max-height:50vh;}
    .tekst{width: 50%;}
    .cta{left:30%; position: absolute;}
    .start{background-image: url("https://interactief.vrtnws.be/gravenvanbekendebelgen/fotos/grafback_2.jpg");}
}