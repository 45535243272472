
.navigation img{
  width: 50px;
  border-radius: 100%;
}

.navigation {
  width: 100%;
  padding-bottom: 5vh;
  height:100%;
  position: fixed;
  top:0;
  left: 0;
  transform: translate3d(-100vw, 0, 0);
  transition: transform .3s cubic-bezier(0, .52, 0, 1);
  overflow:auto;
  z-index: 1000;
  -webkit-overflow-scrolling: touch;

}

.item{
  padding: 20px 10px 20px 30px;
  background-color: #f1f2f4;
  display: flex;
  display: -ms-flexbox;
  align-items: center;
  border-bottom: 3px solid white;
}

.item:hover{
  background-color: #BBE0F0;
  cursor: pointer;
}


.item:last-child{
  border-bottom: transparent;
}

.item h2{
  padding-left: 10px;
  color: #16284A;
    display: block;
}

#hide{
  transform: translate3d(-100vw, 0, 0);
}

#show{
  transform: translate3d(0vw, 0, 0);
}


@media only screen and (min-width: 1020px) {
  #hide{
    transform: translate3d(0vw, 0, 0);
  }
}

/*
vrtnws
const config = {
    apiKey: "AIzaSyA8p0xYVlP8PIz0KK-2hab1MC9fKJuChiA",
    authDomain: "api-project-7429617504.firebaseapp.com",
    databaseURL: "https://api-project-7429617504.firebaseio.com",
    projectId: "api-project-7429617504",
    storageBucket: "api-project-7429617504.appspot.com",
    messagingSenderId: "7429617504"
};


robbe
const config = {
    apiKey: "AIzaSyDOSKl2zzTZtidmAWGdJZr0JQ5EdS8q1tU",
    authDomain: "testing-89824.firebaseapp.com",
    databaseURL: "https://testing-89824.firebaseio.com",
    projectId: "testing-89824",
    storageBucket: "testing-89824.appspot.com",
    messagingSenderId: "841545172903"
};

 */